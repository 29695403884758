import { DateTime } from 'luxon';
import { PayCycleEstimate } from '@thera-hr/api-sdk/api/resources/api/resources/thera/resources/employment';

import { ICompany, IInvoice, IInvoiceInfo } from 'types';
import { dateStringToSpecifiedDateFormat, dateStringToinvoiceDateFormat } from './dateTime';
import {
  InvoiceStatus,
  ItemTypeInvoices,
  contractTypeLabel,
  contractTypeMap,
  invoiceStatusTextStyle,
} from 'lib/constants';
import { IInvoiceWithContractAndCompany } from 'components/app-components/invoice/ContractorInvoiceHistoryTable';
import { IMilestone } from 'components/app-components/add-contractor/types';
import { captureErrorInSentry } from './error';

export type GroupByField = 'contractor' | 'currency' | 'contractType' | 'payFrequency' | 'payCycleDate';
export type GroupByFieldV2 = 'CONTRACTOR' | 'CONTRACT';

export interface IInvoiceStats {
  userID?: string;
  firstName?: string;
  lastName?: string;
  totalAmount: number;
  contractID: string;
  approvedCount: number;
  overdueCount: number;
  processingCount: number;
  pendingReviewCount: number;
  paidCount: number;
  voidOrFailedCount: number;
  contractType?: string;
  invoiceCycleStartDate: string;
  invoiceCycleEndDate: string;
  invoiceCycleDueDate: string;
  currency: string;
  groupType: 'PAYCYCLE' | 'ITEMORONEOFF' | 'MILESTONE';
  allPaid: boolean;
  status: 'PAID' | 'OVERDUE' | 'DUE' | 'PROCESSING' | 'VOID';
  paymentTimestamp: number;
  type: IInvoice['type'] | 'One-Off Payment';
}

export function filterInvoices(invoices: IInvoiceInfo[], searchTerm: string) {
  if (!searchTerm) return invoices;
  // ensure the search term is lower case
  const lowerCaseSearchTerm = searchTerm?.toLowerCase();

  // filter the invoices
  return invoices.filter((invoice) => {
    const recipientUser = invoice.recipientUser;

    // if there is no recipient user, filter out this invoice
    if (!recipientUser) return false;

    // check if the search term is in any of the fields (firstName, lastName, email)
    return (
      recipientUser.firstName.toLowerCase().includes(lowerCaseSearchTerm) ||
      recipientUser.lastName.toLowerCase().includes(lowerCaseSearchTerm) ||
      recipientUser.email.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });
}

// not used, see below
export function groupInvoices_(invoiceInfos: IInvoiceInfo[], groupBy: GroupByField): Record<string, IInvoiceInfo[]> {
  return invoiceInfos.reduce((grouped: Record<string, IInvoiceInfo[]>, invoiceInfo: IInvoiceInfo) => {
    let groupByKey: string | undefined;

    if (groupBy === 'contractor') {
      groupByKey = invoiceInfo.recipientUser?.userID;
    } else if (groupBy === 'currency') {
      groupByKey = invoiceInfo.invoice?.currency;
    } else if (groupBy === 'contractType') {
      groupByKey = invoiceInfo.contract?.contractType;
    } else if (groupBy === 'payFrequency') {
      groupByKey = invoiceInfo.contract?.wageType;
    }

    if (!invoiceInfo.invoice || !groupByKey) return grouped;

    return {
      ...grouped,
      [groupByKey]: grouped[groupByKey] ? [...grouped[groupByKey], invoiceInfo] : [invoiceInfo],
    };
  }, {});
}

// same as above
export function groupInvoices(
  invoiceInfos: IInvoiceInfo[],
  groupBy: GroupByField,
): Record<string, Record<string, IInvoiceInfo[]>> {
  const groupedByKey: Record<string, IInvoiceInfo[]> = {};
  const groupedByInvoiceType: Record<string, Record<string, IInvoiceInfo[]>> = {};

  // group invoices by key
  for (const invoiceInfo of invoiceInfos) {
    let groupByKey: string | undefined;

    if (groupBy === 'contractor') {
      groupByKey = invoiceInfo.recipientUser?.userID;
    } else if (groupBy === 'currency') {
      groupByKey = invoiceInfo.invoice?.currency;
    } else if (groupBy === 'contractType') {
      groupByKey = invoiceInfo.contract?.contractType;
    } else if (groupBy === 'payFrequency') {
      groupByKey = invoiceInfo.contract?.wageType;
    }

    if (!invoiceInfo.invoice || !groupByKey) continue;

    if (!groupedByKey[groupByKey]) {
      groupedByKey[groupByKey] = [];
    }

    groupedByKey[groupByKey].push(invoiceInfo);
  }

  // group the grouped (by key) invoices by invoice type
  Object.entries(groupedByKey).forEach(([key, invoices]) => {
    let groupByKey: string | undefined;
    const _groupedInvoices: Record<string, IInvoiceInfo[]> = {};

    for (const invoiceInfo of invoices) {
      if (invoiceInfo.invoice?.startDate) {
        // Invoices can be a regular invoice and item type but not one-off
        groupByKey = `${invoiceInfo.recipientUser?.userID}_PAYCYCLE_${invoiceInfo.invoice?.startDate}_${invoiceInfo.invoice?.endDate}`;
      } else if (isMilestoneInvoice(invoiceInfo)) {
        // Checks if the contractor is Milestone type and invoice is Milestone type
        // Invoices can be a regular invoice but not one-off or item type
        groupByKey = `MILESTONE_${invoiceInfo.invoice?.displayID}`;
      } else {
        groupByKey = `ITEMORONEOFF_${invoiceInfo.invoice?.displayID}`;
      }

      if (!invoiceInfo.invoice || !groupByKey) continue;
      if (!_groupedInvoices[groupByKey]) {
        _groupedInvoices[groupByKey] = [];
      }
      _groupedInvoices[groupByKey].push(invoiceInfo);
    }
    groupedByInvoiceType[key] = _groupedInvoices;
  });
  return groupedByInvoiceType;
}

export function groupInvoicesByCompanyName(
  invoices: IInvoiceWithContractAndCompany[],
): Record<string, Record<string, IInvoiceWithContractAndCompany[]>> {
  const groupedByKey: Record<string, IInvoiceWithContractAndCompany[]> = {};
  const groupedByInvoiceType: Record<string, Record<string, IInvoiceWithContractAndCompany[]>> = {};

  // group invoices by key
  for (const invoice of invoices) {
    const groupByKey = invoice?.company?.companyName;
    if (!invoice || !groupByKey) continue;
    if (!groupedByKey[groupByKey]) {
      groupedByKey[groupByKey] = [];
    }
    groupedByKey[groupByKey].push(invoice);
  }

  // group the grouped (by key) invoices by invoice type
  Object.entries(groupedByKey).forEach(([key, invoicesWithContractAndCompany]) => {
    let groupByKey: string | undefined;
    const _groupedInvoices: Record<string, IInvoiceWithContractAndCompany[]> = {};

    for (const invoiceWithContractAndCompany of invoicesWithContractAndCompany) {
      if (invoiceWithContractAndCompany?.invoice.startDate) {
        // Invoices can be a regular invoice and item type but not one-off
        groupByKey = `${invoiceWithContractAndCompany.company.companyName}_PAYCYCLE_${invoiceWithContractAndCompany?.invoice.startDate}_${invoiceWithContractAndCompany?.invoice.endDate}`;
      } else if (invoiceWithContractAndCompany.invoice.type === 'MILESTONE') {
        // Checks if the contractor is Milestone type and invoice is Milestone type
        // Invoices can be a regular milestone invoice but not one-off or item type
        groupByKey = `MILESTONE_${invoiceWithContractAndCompany?.invoice.displayID}`;
      } else {
        groupByKey = `ITEMORONEOFF_${invoiceWithContractAndCompany?.invoice.displayID}`;
      }

      if (!invoiceWithContractAndCompany || !groupByKey) continue;
      if (!_groupedInvoices[groupByKey]) {
        _groupedInvoices[groupByKey] = [];
      }
      _groupedInvoices[groupByKey].push(invoiceWithContractAndCompany);
    }
    groupedByInvoiceType[key] = _groupedInvoices;
  });
  return groupedByInvoiceType;
}

export const checkIfInvoiceOverdue = (paymentDueDate: string): boolean => {
  if (!paymentDueDate) return false;
  const dueDate = DateTime.fromISO(paymentDueDate, { zone: 'utc' }).toISODate();
  const now = DateTime.utc().toISODate();
  return now > dueDate;
};

export const checkIfInvoiceDue = (paymentDueDate: string): boolean => {
  if (!paymentDueDate) return false;
  const dueDate = DateTime.fromISO(paymentDueDate, { zone: 'utc' }).toISODate();
  const now = DateTime.utc().toISODate();
  return now === dueDate;
};

export const invoiceDateRangeReadableDate = (invoice: IInvoice) => {
  if (!invoice?.startDate || !invoice?.endDate) return '';
  return `${dateStringToinvoiceDateFormat(invoice.startDate, 'dd LLL')} - ${dateStringToinvoiceDateFormat(
    invoice.endDate,
    'dd LLL',
  )}`;
};

export const getContractorName = (contractorID: string, invoiceInfo: IInvoiceInfo) => {
  const user = invoiceInfo?.recipientUser;
  return user?.firstName + ' ' + user?.lastName;
};

export const getGroupByItemTitle = (groupBy: GroupByField, key: string, invoiceInfo: IInvoiceInfo) => {
  if (groupBy === 'contractor') return getContractorName(key, invoiceInfo);
  if (groupBy === 'contractType') return contractTypeLabel[key];
  if (groupBy === 'payCycleDate') {
    const invoiceType = key.split('_')[1];
    if (invoiceType === 'ITEMORONEOFF') return 'One-Off Payment';
    return `${dateStringToSpecifiedDateFormat(key.split('_')[2], 'LLL dd')} - ${dateStringToSpecifiedDateFormat(
      key.split('_')[3],
      'LLL dd',
    )}`;
  }
  return key;
};

export const isFlexibleRateWorkInvoice = (invoiceArg: IInvoiceInfo | IInvoiceWithContractAndCompany) => {
  return (
    invoiceArg?.invoice?.startDate &&
    invoiceArg?.invoice?.type === 'INVOICE' &&
    invoiceArg?.contract.contractType === 'FLEXIBLE_RATE'
  );
};

export const isFixedRateWorkInvoice = (invoiceArg: IInvoiceInfo | IInvoiceWithContractAndCompany) => {
  return (
    invoiceArg?.invoice?.startDate &&
    invoiceArg?.invoice?.type === 'INVOICE' &&
    invoiceArg?.contract.contractType === 'FIXED_RATE'
  );
};

export const isOneOffPaymentInvoice = (invoiceArg: IInvoiceInfo | IInvoiceWithContractAndCompany | IInvoice) => {
  const invoiceInfo = invoiceArg as IInvoiceInfo | IInvoiceWithContractAndCompany;
  const invoice = invoiceArg as IInvoice;
  if (invoiceInfo?.invoice) {
    return !invoiceInfo?.invoice?.startDate && invoiceInfo?.invoice?.type === 'INVOICE';
  }
  return !invoice?.startDate && invoice?.type === 'INVOICE';
};

export const isMilestoneInvoice = (invoiceArg: IInvoiceInfo | IInvoiceWithContractAndCompany) => {
  return invoiceArg?.contract.contractType === 'MILESTONE' && invoiceArg?.invoice.type === 'MILESTONE';
};

export const isItemTypeInvoice = (invoiceInfo: IInvoiceInfo) => {
  return Object.keys(ItemTypeInvoices).includes(invoiceInfo?.invoice?.type);
};

export const isWorkTypeInvoice = (invoiceInfo: IInvoiceInfo) => {
  return (
    isFlexibleRateWorkInvoice(invoiceInfo) || isFixedRateWorkInvoice(invoiceInfo) || isMilestoneInvoice(invoiceInfo)
  );
};

export const isInvoiceOpen = (invoiceArg: IInvoiceInfo | IInvoiceWithContractAndCompany | IInvoice) => {
  const invoiceInfo = invoiceArg as IInvoiceInfo | IInvoiceWithContractAndCompany;
  const invoice = invoiceArg as IInvoice;
  const openStatuses = [InvoiceStatus.REQUIRES_ACTION, InvoiceStatus.OPEN];
  if (invoiceInfo?.invoice) {
    return openStatuses.includes(invoiceInfo.invoice.status as InvoiceStatus);
  }
  return openStatuses.includes(invoice?.status as InvoiceStatus);
};

export const isInvoiceUnapproved = (invoiceArg: IInvoiceInfo | IInvoiceWithContractAndCompany | IInvoice) => {
  const invoiceInfo = invoiceArg as IInvoiceInfo | IInvoiceWithContractAndCompany;
  const invoice = invoiceArg as IInvoice;
  if (invoiceInfo.invoice) {
    return invoiceInfo.invoice.approvalStatus === 'UNAPPROVED';
  }
  return invoice.approvalStatus === 'UNAPPROVED';
};

export const isInvoiceApproved = (invoiceArg: IInvoiceInfo | IInvoiceWithContractAndCompany | IInvoice) => {
  const invoiceInfo = invoiceArg as IInvoiceInfo | IInvoiceWithContractAndCompany;
  const invoice = invoiceArg as IInvoice;
  if (invoiceInfo.invoice) {
    return invoiceInfo.invoice.approvalStatus === 'APPROVED';
  }
  return invoice.approvalStatus === 'APPROVED';
};

export const isInvoiceInCurrentCycle = (
  invoiceArg: IInvoiceInfo | IInvoiceWithContractAndCompany,
  payCycle: PayCycleEstimate,
) => {
  return invoiceArg.invoice.startDate === payCycle?.startDate && invoiceArg.invoice.endDate === payCycle?.endDate;
};

export const isInvoicePaid = (invoiceArg: IInvoiceInfo | IInvoiceWithContractAndCompany | IInvoice) => {
  const invoiceInfo = invoiceArg as IInvoiceInfo | IInvoiceWithContractAndCompany;
  const invoice = invoiceArg as IInvoice;
  if (invoiceInfo.invoice) {
    return invoiceStatusTextStyle[invoiceInfo.invoice.status]?.text.toUpperCase() === 'PAID';
  }
  return invoiceStatusTextStyle[invoice.status]?.text.toUpperCase() === 'PAID';
};

export const isInvoiceProcessing = (invoiceArg: IInvoiceInfo | IInvoiceWithContractAndCompany | IInvoice) => {
  const invoiceInfo = invoiceArg as IInvoiceInfo | IInvoiceWithContractAndCompany;
  const invoice = invoiceArg as IInvoice;
  if (invoiceInfo.invoice) {
    return invoiceStatusTextStyle[invoiceInfo.invoice.status]?.text.toUpperCase() === 'PROCESSING';
  }
  return invoiceStatusTextStyle[invoice.status]?.text.toUpperCase() === 'PROCESSING';
};

export const isInvoiceVoidOrFailed = (invoiceArg: IInvoiceInfo | IInvoiceWithContractAndCompany | IInvoice) => {
  /* NOTE: This definition is used to categorise invoices. Right now void and failed 
  invoices belong to the same category
   */
  const invoiceInfo = invoiceArg as IInvoiceInfo | IInvoiceWithContractAndCompany;
  const invoice = invoiceArg as IInvoice;
  if (invoiceInfo.invoice) {
    return (
      invoiceStatusTextStyle[invoiceInfo.invoice.status]?.text.toUpperCase() === 'VOID' ||
      invoiceStatusTextStyle[invoiceInfo.invoice.status]?.text.toUpperCase() === 'FAILED'
    );
  }
  return (
    invoiceStatusTextStyle[invoice.status]?.text.toUpperCase() === 'VOID' ||
    invoiceStatusTextStyle[invoice.status]?.text.toUpperCase() === 'FAILED'
  );
};

export const isInvoiceFailed = (invoiceArg: IInvoiceInfo | IInvoiceWithContractAndCompany | IInvoice) => {
  const invoiceInfo = invoiceArg as IInvoiceInfo | IInvoiceWithContractAndCompany;
  const invoice = invoiceArg as IInvoice;
  if (invoiceInfo.invoice) {
    return invoiceStatusTextStyle[invoiceInfo.invoice.status]?.text.toUpperCase() === 'FAILED';
  }
  return invoiceStatusTextStyle[invoice.status]?.text.toUpperCase() === 'FAILED';
};

export const isInvoiceInPayableDateRange = (invoiceInfo: IInvoiceInfo, company: ICompany) => {
  return (
    !invoiceInfo.invoice.paymentDueDate ||
    company?.settings?.paymentAllowedDaysBeforeDueDate >
      DateTime.fromISO(invoiceInfo.invoice.paymentDueDate).diff(DateTime.utc(), ['days']).days
  );
};

/*
- InvoiceStats are used to display information on the Invoice Cycle blocks (at Invoice History, Review/Pay Pages, etc)  and InvoiceBreakupModal 
- id parameter is of the format uniqueValue_groupType_startDate_endDate where groupType is PAYCYCLE 
and uniqueValue_groupType_invoiceDisplayID for MILESTONE AND ITEMORONEOFF
*/
export const getInvoicesStats = (id: string, invoicesArg: IInvoiceInfo[] | IInvoice[]) => {
  const invoicesInfo = invoicesArg as IInvoiceInfo[];
  const allInvoices = invoicesArg as IInvoice[];

  const firstInvoice = invoicesInfo[0].invoice ? invoicesInfo[0].invoice : allInvoices[0];
  const contractType = invoicesInfo[0].contract ? contractTypeMap[invoicesInfo[0].contract.contractType] : undefined;
  const userID = invoicesInfo[0].recipientUser ? invoicesInfo[0].recipientUser.userID : undefined;
  const firstName = invoicesInfo[0].recipientUser ? invoicesInfo[0].recipientUser.firstName : undefined;
  const lastName = invoicesInfo[0].recipientUser ? invoicesInfo[0].recipientUser.lastName : undefined;

  const invoiceCycleStats: IInvoiceStats = {
    userID: userID,
    firstName: firstName,
    lastName: lastName,
    totalAmount: 0,
    contractID: firstInvoice.contractID,
    approvedCount: 0,
    pendingReviewCount: 0,
    overdueCount: 0,
    processingCount: 0,
    paidCount: 0,
    voidOrFailedCount: 0,
    invoiceCycleStartDate: firstInvoice.startDate,
    invoiceCycleEndDate: firstInvoice.endDate,
    invoiceCycleDueDate: firstInvoice.paymentDueDate,
    currency: firstInvoice.currency,
    contractType: contractType,
    groupType: undefined,
    allPaid: undefined,
    status: undefined,
    paymentTimestamp: undefined,
    type: undefined,
  };
  const groupType = id.split('_')[1];
  if (groupType === 'PAYCYCLE') {
    /*
    calculate stats for invoices that are part of a Pay Cycle (Regular Invoices and Item Type Invoices for Flexible and Fixed Rate Contractors)
    */
    invoiceCycleStats.groupType = 'PAYCYCLE';
    (invoicesInfo || allInvoices).forEach((_invoiceInfoOrInvoice: IInvoiceInfo | IInvoice) => {
      const _invoiceInfo = _invoiceInfoOrInvoice as IInvoiceInfo;
      const _invoice = _invoiceInfoOrInvoice as IInvoice;

      let invoice: IInvoice;
      if (_invoiceInfo.invoice) {
        invoice = _invoiceInfo?.invoice;
      } else {
        invoice = _invoice;
      }
      if (!isInvoiceVoidOrFailed(invoice)) invoiceCycleStats.totalAmount += invoice.amount;
      else {
        invoiceCycleStats.voidOrFailedCount++;
      }
      if (isInvoiceOpen(invoice)) {
        if (isInvoiceApproved(invoice)) {
          invoiceCycleStats.approvedCount++;
        } else if (isInvoiceUnapproved(invoice)) {
          invoiceCycleStats.pendingReviewCount++;
        }
        if (checkIfInvoiceOverdue(invoice.paymentDueDate)) {
          invoiceCycleStats.overdueCount++;
        }
      } else if (isInvoicePaid(invoice)) {
        invoiceCycleStats.paidCount++;
      } else if (invoice.status === 'PROCESSING') {
        invoiceCycleStats.processingCount++;
      }
    });
    if (
      invoiceCycleStats.approvedCount === 0 &&
      invoiceCycleStats.pendingReviewCount === 0 &&
      invoiceCycleStats.processingCount === 0 &&
      invoiceCycleStats.voidOrFailedCount === 0 &&
      invoiceCycleStats.paidCount > 0
    ) {
      invoiceCycleStats.allPaid = true;
    }
    invoiceCycleStats.status = getInvoiceCycleStatus(invoiceCycleStats);
  } else {
    /*
    calculate stats for invoices that are NOT part of a Pay Cycle (One-Off Payments, and
    Milestone Invoices and Item Type Invoices for Milestone Contractors)


    Note: these types of invoices only have 1 invoice in each invoicesInfo object
    */
    (invoicesInfo || allInvoices).forEach((_invoiceInfoOrInvoice: IInvoiceInfo | IInvoice) => {
      const _invoiceInfo = _invoiceInfoOrInvoice as IInvoiceInfo;
      const _invoice = _invoiceInfoOrInvoice as IInvoice;

      let invoice: IInvoice;
      if (_invoiceInfo.invoice) {
        invoice = _invoiceInfo?.invoice;
      } else {
        invoice = _invoice;
      }

      if (groupType === 'MILESTONE') {
        invoiceCycleStats.groupType = 'MILESTONE';
      } else {
        invoiceCycleStats.type = isOneOffPaymentInvoice(invoice) ? 'One-Off Payment' : invoice.type;
        invoiceCycleStats.groupType = 'ITEMORONEOFF';
      }

      invoiceCycleStats.approvedCount = 0;
      invoiceCycleStats.pendingReviewCount = 0;
      invoiceCycleStats.totalAmount += invoice.amount;

      if (!isInvoicePaid(invoice)) {
        if (isInvoiceVoidOrFailed(invoice)) {
          invoiceCycleStats.status = 'VOID';
        } else if (invoice.status === 'PROCESSING') invoiceCycleStats.status = 'PROCESSING';
        else invoiceCycleStats.status = 'DUE';
      } else {
        invoiceCycleStats.status = 'PAID';
        invoiceCycleStats.allPaid = true;
      }
    });
  }

  return invoiceCycleStats;
};

const getInvoiceCycleStatus = (invoiceCycleStats: IInvoiceStats) => {
  let status = 'DUE';
  if (invoiceCycleStats.approvedCount === 0 && invoiceCycleStats.pendingReviewCount === 0) {
    if (invoiceCycleStats.processingCount > 0) status = 'PROCESSING';
    else if (invoiceCycleStats.paidCount > 0) status = 'PAID';
    else if (invoiceCycleStats.paidCount === 0) status = 'VOID';
  } else if (invoiceCycleStats.overdueCount > 0) {
    status = 'OVERDUE';
  } else if (!checkIfInvoiceDue(invoiceCycleStats?.invoiceCycleDueDate)) {
    status = 'NOT_DUE';
  } else {
    status = 'DUE';
  }
  return status as IInvoiceStats['status'];
};

/**
 * Returns sorted milestones object, with completed milestones last
 */
export const getMilestonesSortedByStatus = (milestones: { [key: string]: IMilestone }) => {
  if (!milestones || !Object.keys(milestones)?.length) return {};
  // Separate completed and incomplete milestones
  const completedMilestones = {};
  const incompleteMilestones = {};

  for (const key in milestones) {
    const milestone = milestones[key];
    if (milestone.status === 'COMPLETE') {
      completedMilestones[key] = milestone;
    } else {
      incompleteMilestones[key] = milestone;
    }
  }

  // Combine completed and incomplete milestones while putting completed ones last
  const sortedMilestones = { ...incompleteMilestones, ...completedMilestones };

  return sortedMilestones;
};

/** Returns payment due string for display */
export const getPaymentDueString = (paymentDueDays: number) => {
  if (!paymentDueDays || paymentDueDays === 0) {
    return 'Same day';
  }
  return `${paymentDueDays} day${paymentDueDays > 1 ? 's' : ''} later`;
};

/** Returns cycle ends on string for display */
export const getCycleEndString = (cycleEnd: {
  type: string;
  dayOfWeek?: string;
  value?: { type: string; value: any };
}): string => {
  const { type, value, dayOfWeek } = cycleEnd;

  try {
    if (type === 'weekly' || type === 'biweekly') {
      // Convert the dayOfWeek to title case
      const formattedDayOfWeek = dayOfWeek.charAt(0) + dayOfWeek.slice(1).toLowerCase();
      if (type === 'weekly') {
        return 'Every ' + formattedDayOfWeek;
      }
      return 'Every other ' + formattedDayOfWeek;
    }

    if (type === 'semimonthly') {
      if (value.type === 'fifteenthAndEndOfMonth') {
        return 'On the 15th and last day of the month';
      }
      // else value.type = dayOfMonth
      return 'On the ' + value.value + 'th of the month';
    }

    if (type === 'monthly') {
      if (value.type === 'lastDayOfMonth') {
        return 'On the last day of the month';
      }
      if (value.type === 'dayOfMonth') {
        return 'On the ' + value.value + 'th of the month';
      }
      // else value.type = lastDayOfWeekOfMonth
      return 'On the last ' + value.value + ' of the month';
    }
  } catch (error) {
    console.error(error);
    captureErrorInSentry(error, cycleEnd, 'Error in getCycleEndString');
    return '';
  }
};

export const getInvoiceStatusText = (invoiceArg: IInvoiceInfo | IInvoice): 'OPEN' | 'APPROVED' | 'VOID' | 'PAID' => {
  const invoiceInfo = invoiceArg as IInvoiceInfo;
  const invoice = invoiceArg as IInvoice;

  if (invoiceInfo.invoice) {
    const status = invoiceInfo.invoice.status;
    if (
      invoiceStatusTextStyle[status?.toUpperCase()]?.text?.toUpperCase() === 'OPEN' &&
      invoiceInfo.invoice.approvalStatus === 'APPROVED'
    ) {
      return 'APPROVED';
    } else {
      return invoiceStatusTextStyle[status?.toUpperCase()]?.text;
    }
  }

  if (
    invoiceStatusTextStyle[invoice.status?.toUpperCase()]?.text?.toUpperCase() === 'OPEN' &&
    invoice.approvalStatus === 'APPROVED'
  ) {
    return 'APPROVED';
  } else {
    return invoiceStatusTextStyle[invoice.status?.toUpperCase()]?.text;
  }
};

export const getDueStatusText = (invoiceArg: IInvoiceInfo | IInvoice): 'DUE' | 'NOT DUE' | 'OVERDUE' => {
  const invoiceInfo = invoiceArg as IInvoiceInfo;
  const invoice = invoiceArg as IInvoice;

  if (invoiceInfo.invoice) {
    if (checkIfInvoiceOverdue(invoiceInfo.invoice.paymentDueDate)) {
      return 'OVERDUE';
    } else if (checkIfInvoiceDue(invoiceInfo.invoice.paymentDueDate)) {
      return 'DUE';
    } else {
      return 'NOT DUE';
    }
  }

  if (checkIfInvoiceOverdue(invoice.paymentDueDate)) {
    return 'OVERDUE';
  } else if (checkIfInvoiceDue(invoice.paymentDueDate)) {
    return 'DUE';
  } else {
    return 'NOT DUE';
  }
};

export const showDueStatus = (invoiceArg: IInvoiceInfo | IInvoice) => {
  const invoiceInfo = invoiceArg as IInvoiceInfo;
  const invoice = invoiceArg as IInvoice;

  if (invoiceInfo.invoice) {
    if (isInvoicePaid(invoiceInfo) || isInvoiceVoidOrFailed(invoiceInfo)) {
      return false;
    }
    return true;
  }

  if (isInvoicePaid(invoice) || isInvoiceVoidOrFailed(invoice)) {
    return false;
  }
  return true;
};
