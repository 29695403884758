import { DateTime } from 'luxon';

export const convertDateToFormat = (date: string | number, format: string) => {
  const dateValue = typeof date == 'number' ? date * 1000 : date;
  const innerDateObject = new Date(dateValue);

  return DateTime.fromISO(innerDateObject.toISOString()).toFormat(format);
};

export const invoiceDateFormat = 'MMMM dd, yyyy';
export const defaultDateFormat = 'MMMM dd, yyyy';
/**
 * Convert a timestamp to a date string
 * @param {number} timestamp - The timestamp to convert (default: current timestamp)
 * @param {string} format - The format to return the date in (default: yyyy-MM-dd)
 * @returns {string} - The date string in the desired format e.g. 2020-01-01
 */
export const timestampToDateString = ({
  timestamp = DateTime.now().toSeconds(),
  format = 'yyyy-MM-dd',
}: {
  timestamp?: number;
  format?: string;
}): string => {
  // Convert the timestamp to a DateTime object
  const date = DateTime.fromSeconds(timestamp);
  // Return the date in the desired format
  return date.toFormat(format);
};

/**
 * Convert a date string to a date string in the format used in invoices
 * @param {string} dateString - The date string to convert e.g. 2020-01-01
 * @returns {string} - The date string in the desired format e.g. January 01, 2020
 * @example dateStringToinvoiceDateFormat('2020-01-01') // January 01, 2020
 */
export const dateStringToinvoiceDateFormat = (dateString: string, format = invoiceDateFormat): string => {
  // Convert the timestamp to a DateTime object
  const date = DateTime.fromISO(dateString);
  // Return the date in the desired format
  return date.toFormat(format);
};

/**
 * Convert a date string to a date string in the format specified
 * @param {string} dateString - The date string to convert e.g. 2020-01-01
 * @returns {string} - The date string in the desired format e.g. January 01, 2020
 * @example dateStringToSpecifiedDateFormat('2020-01-01') // January 01, 2020
 */
export const dateStringToSpecifiedDateFormat = (dateString: string, format = defaultDateFormat): string => {
  // Convert the timestamp to a DateTime object
  const date = DateTime.fromISO(dateString);
  // Return the date in the desired format
  return date.toFormat(format);
};

/**
 * Calculate the due date for an invoice
 * @param {string} startDate - The start date of the invoice e.g. 2020-01-01
 * @param {number} noOfAdvanceDays - The number of days to add to the start date (default: 7)
 * @param {string} format - The format to return the date in (default: yyyy-MM-dd)
 * @returns {string} - The due date in the desired format e.g. 2020-01-08
 */
export const calculateDueDate = ({
  startDateTimestamp = DateTime.now().toSeconds(),
  noOfAdvanceDays = 7,
  format = 'yyyy-MM-dd',
}): string => {
  // Parse the input string to DateTime object
  // const start = DateTime.fromFormat(startDate, 'yyyy-MM-dd');
  const start = DateTime.fromSeconds(startDateTimestamp);
  // Add 7 days to the start date
  const dueDate = start.plus({ days: noOfAdvanceDays });
  // Return the due date in the desired format
  return dueDate.toFormat(format);
};

export const dateOrdinalSuffix = (date: string): 'st' | 'nd' | 'rd' | 'th' => {
  // convert the ISO date string to a DateTime object
  const dateObject = DateTime.fromISO(date);
  const day = dateObject.day;
  switch (day) {
    case 1:
    case 21:
    case 31:
      return 'st';
    case 2:
    case 22:
      return 'nd';
    case 3:
    case 23:
      return 'rd';
    default:
      return 'th';
  }
};

export function formatWithOrdinal(date: DateTime): string {
  const day = date.day;
  let ordinal = '';
  switch (day) {
    case 1:
    case 21:
    case 31:
      ordinal = 'st';
      break;
    case 2:
    case 22:
      ordinal = 'nd';
      break;
    case 3:
    case 23:
      ordinal = 'rd';
      break;
    default:
      ordinal = 'th';
  }

  return `${date.toFormat('MMM')} ${day}${ordinal}`;
}

export function getOrdinalSuffix(n: number): string {
  if (n > 3 && n < 21) return 'th';
  switch (n % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export const countDaysBetween = (startDate: string, endDate: string, includeWeekends = false) => {
  let start = DateTime.fromISO(startDate);
  let end = DateTime.fromISO(endDate);

  if (end < start) {
    [start, end] = [end, start]; // Swap if end is earlier than start
  }

  let days = 0;
  while (start <= end) {
    const dayOfWeek = start.weekday;

    // If includeWeekends is true, increment days for all days
    // Else, only increment for weekdays (Monday is 1, Friday is 5)
    if (includeWeekends || (dayOfWeek >= 1 && dayOfWeek <= 5)) {
      days++;
    }

    start = start.plus({ days: 1 });
  }

  return days;
};
