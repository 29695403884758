import { GetTeamsResponse } from '@thera-hr/api-sdk/api/resources/api/resources/thera/resources/company';
import useSWR from 'swr';

import { useUser } from 'context/user';
import { useCompany } from 'context/company';

export const useTeamsSWR = () => {
  const { theraFernApi, isLoading: isUserLoading, permissionsVersion } = useUser();
  const { company, isLoading: isCompanyLoading } = useCompany();
  const companyId = company?.companyID;

  const getTeamsFetcher = async (): Promise<GetTeamsResponse> => {
    const response = await theraFernApi.thera.company.companyApi.getTeams(companyId);
    return response;
  };
  const {
    data,
    error,
    mutate,
    isLoading: isSWRLoading,
  } = useSWR(() => (companyId && permissionsVersion === 2 ? ['getTeamsFetcher', companyId] : false), getTeamsFetcher, {
    onErrorRetry: (error) => {
      if (error.status === 404) return;
    },
    refreshInterval: 1000 * 60 * 10,
    revalidateOnMount: true,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });

  if (error) {
    console.error(error);
  }

  const isLoading = isUserLoading || isCompanyLoading || isSWRLoading;

  return {
    teams: data?.teams || {},
    mutate,
    isPending: isLoading,
    error,
  };
};
