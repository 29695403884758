export function fetcher(url: string, jwt: string, method = 'GET', data = {}) {
  return () =>
    fetch(url, {
      method: method,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(jwt && { Authorization: 'Bearer ' + jwt }),
      },
      ...(data && Object.keys(data)?.length && { body: JSON.stringify(data) }),
    }).then((response) => {
      if (!response.ok) {
        if (response.status === 404) {
          return '404';
        }
        const error = new Error('An error occurred while fetching the data.');
        throw error;
      } else {
        const stringPromise = response.json();
        if (response.ok) {
          return stringPromise;
        }
      }
    });
}
