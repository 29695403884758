import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetcher } from 'thera-swr/fetcher';
import getJWT from 'thera-swr/jwt';
import { DateTime } from 'luxon';

import { InvoiceStatus, InvoiceApprovalStatus } from 'lib/constants';
import { IInvoiceInfo } from 'types';
import { buildPath } from 'utils/api';
import {
  checkIfInvoiceOverdue,
  isInvoiceApproved,
  isInvoiceOpen,
  isInvoiceUnapproved,
  isMilestoneInvoice,
} from 'utils/invoice';

interface UseAdminInvoiceSWRProps {
  companyID: string;
  status?: InvoiceStatus;
  approvalStatus?: InvoiceApprovalStatus;
  companyInvoiceAvailabilityDaysCount?: number;
}

export default function useAdminInvoiceSWR({
  companyID,
  status,
  approvalStatus,
  companyInvoiceAvailabilityDaysCount,
}: UseAdminInvoiceSWRProps) {
  const { jwt } = getJWT();
  const path = buildPath(`/companies/${companyID}/invoices`, {
    status: status,
    approvalStatus: approvalStatus,
  });

  const [countStats, setCountStats] = useState({
    openCount: 0,
    approvedCount: 0,
    overdueCount: 0,
  });
  const {
    data: invoicesInfo = [],
    error,
    mutate,
    isLoading,
  } = useSWR<IInvoiceInfo[]>(
    () => (companyID ? [path, jwt] : false),
    fetcher(process.env.NEXT_PUBLIC_BACKEND_URL + path, jwt),
    {
      refreshInterval: 1000 * 60 * 5,
      revalidateOnMount: true,
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    },
  );

  useEffect(() => {
    if (!invoicesInfo?.length) return;

    let openUnapprovedInvoicesCount = 0;
    let approvedInvoicesCount = 0;
    let overdueInvoicesCount = 0;
    invoicesInfo?.forEach((invoiceInfo) => {
      if (
        isInvoiceOpen(invoiceInfo) &&
        isInvoiceApproved(invoiceInfo) &&
        (companyInvoiceAvailabilityDaysCount && invoiceInfo.invoice.paymentDueDate
          ? companyInvoiceAvailabilityDaysCount >=
            DateTime.fromISO(invoiceInfo.invoice.paymentDueDate).diff(DateTime.utc(), ['days']).days
          : true)
      ) {
        approvedInvoicesCount++;
      }
      if (
        isInvoiceOpen(invoiceInfo) &&
        (isInvoiceUnapproved(invoiceInfo) || invoiceInfo.invoice.approvalStatus === null)
      ) {
        openUnapprovedInvoicesCount++;
      }
      if (
        checkIfInvoiceOverdue(invoiceInfo.invoice.paymentDueDate) &&
        isInvoiceApproved(invoiceInfo) &&
        isInvoiceOpen(invoiceInfo) &&
        !isMilestoneInvoice(invoiceInfo) &&
        (companyInvoiceAvailabilityDaysCount && invoiceInfo.invoice.paymentDueDate
          ? companyInvoiceAvailabilityDaysCount >=
            DateTime.fromISO(invoiceInfo.invoice.paymentDueDate).diff(DateTime.utc(), ['days']).days
          : true)
      ) {
        overdueInvoicesCount++;
      }
    });

    setCountStats((prevState) => ({
      ...prevState,
      openCount: openUnapprovedInvoicesCount,
      approvedCount: approvedInvoicesCount,
      overdueCount: overdueInvoicesCount,
    }));
  }, [companyID, companyInvoiceAvailabilityDaysCount, invoicesInfo]);

  return {
    invoices: invoicesInfo,
    mutateAdminInvoices: mutate,
    /** count of all the Open and Unapproved invoices based on companyInvoiceAvailabilityDaysCount.
     *  Note: invoices without paymentDueDate are also included
     */
    openInvoicesCount: countStats.openCount || 0,
    /** count of all the Open and Approved invoices */
    approvedInvoicesCount: countStats.approvedCount || 0,
    /** count of all the Open and Overdue invoices based on companyInvoiceAvailabilityDaysCount */
    overdueCount: countStats.overdueCount || 0,
    isPending: isLoading,
    error,
  };
}
