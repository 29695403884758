export const buildPath = (
  basePath: string,
  params: { [key: string]: string | number | boolean | undefined | null },
): string => {
  const query = Object.keys(params)
    .filter((key) => params[key] !== undefined && params[key] !== null)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

  return query ? `${basePath}?${query}` : basePath;
};
